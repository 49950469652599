<template>
    <div>
        <h2 class="primary darken-2 white--text font-weight-bold subheading py-2 text-xs-center">CREATE NEW CAMPAIGN</h2>
        <v-divider></v-divider>
        <v-card color="white pa-3" style="" >
            <p class="dark--text pa-0 ma-0 text-xs-center">With Campaigns you save with attainable objectives.</p>
            <v-layout wrap row>
                <v-flex xs12 md10 offset-md1>
                    <v-layout  row>
                        <v-flex xs2 sm1>
                            <i class="teal--text fas fa-piggy-bank fa-2x mt-3"> </i>                            
                        </v-flex>
                        <v-flex xs9 sm10>
                            <v-text-field class="pa-0 mt-3"
                                ref="campaign_name" v-model="campaignName"
                                label="Enter Campaign Name"
                                :rules="[v => !!v || 'Campaign Name is required']"
                                color="teal"
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs2 >
                            <v-btn @click.stop="$store.commit('activateDialog', true); campaign_name_help = true; getHelpMessage()" flat fab small outline color="black" class="my-4" style="height:20px; width: 20px" >
                                <i class="fas fa-question"> </i>                            
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </v-flex>
                <v-flex xs12 md10 offset-md1>            
                    <v-layout row>
                        <v-flex xs2 sm1>
                            <i class="teal--text fas fa-tag fa-2x mt-3"> </i>                            
                        </v-flex>
                        <v-flex xs9 sm10>
                            <v-select
                                @change="OnChangeCampaignType()"
                                class="pt-0 mt-3" color="teal"
                                v-model="campaignType" ref="campaign_type"
                                :items="campaignTypes"
                                :rules="[v => !!v || 'Campaign Types is required']"
                                label="Select Campaign Types"
                                required
                            ></v-select>
                        </v-flex>
                        <v-flex xs2>
                            <v-btn @click.stop="$store.commit('activateDialog', true); campaign_type_help = true; getHelpMessage()" flat fab small outline color="black" class="my-4" style="height:20px; width: 20px" >
                                <i class="fas fa-question"> </i>                            
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </v-flex>
                <v-flex xs12 md10 offset-md1>            
                    <v-layout  row>
                        <v-flex xs2 sm1>
                            <i class="teal--text fas fa-money-bill fa-2x mt-3"> </i>                            
                        </v-flex>
                        <v-flex xs9 sm10>
                            <v-text-field class="pa-0 mt-3"
                                ref="campaign_target_amount"
                                label="Campaign Amount" v-model="campaignTargetAmount"
                                :disabled='isAmountDisabled'  :required='isAmountRequired'
                                 color="teal"
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs2 >
                            <v-btn @click.stop="$store.commit('activateDialog', true); campaign_amount_help = true; getHelpMessage()" flat fab small outline color="black" class="my-4" style="height:20px; width: 20px" >
                                <i class="fas fa-question"> </i>                            
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </v-flex>
                <v-flex xs12 md10 offset-md1>            
                    <v-layout row>
                        <v-flex xs2 sm1>
                            <i class="teal--text fas fa-calendar fa-2x mt-3"> </i>                            
                        </v-flex>
                        <v-flex xs9 sm10>
                            <v-text-field class="pa-0 mt-3"
                                ref="campaign_target_date"
                                label="Campaign Date" v-model="campaignTargetDate"
                                :disabled='isDateDisabled' :required='isDateRequired'
                                type="date"
                                 color="teal"
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs2 >
                            <v-btn @click.stop="$store.commit('activateDialog', true); campaign_date_help = true; getHelpMessage()" flat fab small outline color="black" class="my-4" style="height:20px; width: 20px" >
                                <i class="fas fa-question"> </i>                            
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </v-flex>
                <v-flex xs12 md10 offset-md1>            
                    <v-layout  row>
                        <v-flex xs2 sm1>
                            <i class="teal--text fas fa-pen-to-square fa-2x mt-3"> </i>                            
                        </v-flex>
                        <v-flex xs9 sm10>
                            <v-textarea class="pa-0 mt-3"
                                name="input-7-1"
                                auto-grow  v-model="campaignDescription"
                                ref="campaign_description"
                                label="Campaign Description"
                                :rules="[v => !!v || 'Campaign Description is required']"
                                background-color="grey lighten-4"  color="teal"
                                rows="2" box
                                row-height="20" 
                            ></v-textarea>
                        </v-flex>
                        <v-flex xs2 >
                            <v-btn @click.stop="$store.commit('activateDialog', true); campaign_description_help = true; getHelpMessage()" flat fab small outline color="black" class="my-4" style="height:20px; width: 20px" >
                                <i class="fas fa-question"> </i>                            
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-layout>
            <div class="text-xs-center">
                <v-btn @click="createNewCampaign()" class="white--text elevation-6" type="submit" small round  color="teal" >
                    <span> Submit</span>
                </v-btn>
            </div>
            <v-dialog width="500px" v-model="Dialog"> 
                <v-card> 
                    <v-card-title class="headline primary darken-2 white--text" primary-title>Welcome To PettyCash</v-card-title> 
                    <v-card-text class="pa-2">
                        <p class=" mb-0">In order to start using our services, you will create your very first savings campaign</p>
                        <div>
                            <p class=" mb-0">Note that we have 3 campaign types (Money based, Time based and Money-Time based).</p>
                            <p class="pl-2 mb-0 pt-1 "> - Money Based campaigns have money as their savings target with minimum being 25,000 F CFA</p>
                            <p class="pl-2 mb-0 pt-1 "> - Time Based campaigns have time as their savings target with minimum being 1 month</p>
                            <p class="pl-2 mb-0 pt-1 "> - Click <b class="teal--text darken-2">Start Now</b> below to begin</p>
                        </div>
                    </v-card-text>

                    <v-card-actions class="px-3 text-xs-right" >
                        <v-spacer></v-spacer>
                        <v-btn color="teal darken-2" class="white--text" @click="Dialog = false" > Start Now </v-btn> 
                    </v-card-actions> 
                </v-card> 
            </v-dialog> 
        </v-card>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {

        data() {
            return {
                isAmountDisabled: false,
                isDateDisabled: false,
                isAmountRequired: false,
                isDateRequired: false,
                campaign_name_help: false,
                campaign_type_help: false,
                campaign_amount_help: false,
                campaign_date_help: false,
                campaign_description_help: false,
                campaignName: "",
                campaignType: "",
                campaignTargetAmount: "",
                campaignTargetDate: "",
                campaignDescription: "",
                campaignTypes: [],
                Dialog: false,
            }
        },
        mounted(){
            // executes these after the page has been mounted
            this.getCampaignTypes();
            this.getMarketItemInfo();

            this.$store.commit('setCloseURL', "/savings/campaigns/active")
            document.title = "PettyCash | Create New Campaign"
            if(sessionStorage.getItem('url').search("signup") === -1){
                this.Dialog = false
            } 
            else{
                this.Dialog = true
            }
            console.log(sessionStorage.getItem('url'))
        },

        methods:{
            async getMarketItemInfo(){
                this.$store.commit('setIsLoading', true)
                const product_id = this.$store.state.save_item
                
                if(product_id !== ""){
                    await axios
                        .get('/api/v1/market_place/get/product/details/'+product_id+'/')
                        .then(response => {
                            console.log(response.data)
                            this.product = response.data["product_info"]  // get the data and fill into campaigns
                            this.campaignName = "Saving to buy "+this.product.name
                            this.campaignTargetAmount = this.product.price
                            this.campaignDescription = "I am saving to buy a "+this.product.name+" of amount "+this.product.price+" F CFA"
                            this.campaignType = "MONEY_TARGET"

                            this.isAmountDisabled = false
                            this.isDateDisabled = true                    
                            this.isAmountRequired = false
                            this.isDateRequired = true                    
                        })
                        .catch(error => {
                            if (error.response){
                                this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                                this.$store.commit('setSnackBarColor', "red darken-4")
                                this.$store.commit('activateSnackBar', true)
                            }else{
                                this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                                this.$store.commit('setSnackBarColor', "red darken-4")
                                this.$store.commit('activateSnackBar', true)
                            }
                        })
                }
                this.$store.commit('setIsLoading', false)
            },

            async getCampaignTypes(){
                this.$store.commit('setIsLoading', true)
                
                await axios
                    .get('/api/v1/savings/get/campaign/types/')
                    .then(response => {
                        this.campaignTypes = response.data  // get the data and fill into campaigns
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })

                this.$store.commit('setIsLoading', false)
            },

            async createNewCampaign(){
                var future = new Date();
                future.setDate(future.getDate() + 30);  // sets the future to 30 days from now
                
                if(this.campaignName === ""){
                    this.$store.commit('setSnackBarMessage', "Please fill in the campaign name")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.campaign_name.focus()  // this causes a an auto fucos to the element
                }
                else if(this.campaignType === ""){
                    this.$store.commit('setSnackBarMessage', "Please select the campaign type")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.campaign_type.focus()  // this causes a an auto fucos to the element
                }
                else if ((this.campaignType === "MONEY_TARGET" || this.campaignType === "MONEY_TIME_TARGET") && Number(this.campaignTargetAmount) < 5000){
                    this.$store.commit('setSnackBarMessage', "Please your campaign target amount should be from 5000 F CFA and above.")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.campaign_target_amount.focus()  // this causes a an auto fucos to the element
                }
                else if ((this.campaignType === "TIME_TARGET" || this.campaignType === "MONEY_TIME_TARGET") && new Date(this.campaignTargetDate) < future){
                    this.$store.commit('setSnackBarMessage', "Sorry your campaign date must be atleast <b>1 month from now</b>.")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.campaign_target_date.focus()  // this causes a an auto fucos to the element
                }
                else if(this.campaignType === "MONEY_TARGET" && this.campaignTargetAmount === ""){
                    this.$store.commit('setSnackBarMessage', "Please input your campaign target amount")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.campaign_target_amount.focus()  // this causes a an auto fucos to the element
                }
                else if(this.campaignType === "TIME_TARGET" && this.campaignTargetDate === ""){
                    this.$store.commit('setSnackBarMessage', "Please select your campaign target date")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.campaign_target_date.focus()  // this causes a an auto fucos to the element
                }
                else if(this.campaignType === "MONEY_TIME_TARGET" && this.campaignTargetDate === ""){
                    this.$store.commit('setSnackBarMessage', "Please select your campaign target date")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.campaign_target_date.focus()  // this causes a an auto fucos to the element
                }
                else if(this.campaignType === "MONEY_TIME_TARGET" && this.campaignTargetAmount === ""){
                    this.$store.commit('setSnackBarMessage', "Please input your campaign target amount")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.campaign_target_amount.focus()  // this causes a an auto fucos to the element
                }
                else if(this.campaignDescription === ""){
                    this.$store.commit('setSnackBarMessage', "Please input your campaign description")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.campaign_description.focus()  // this causes a an auto fucos to the element
                }
                else {
                    this.$store.commit('setIsLoading', true)
                    const product_id = this.$store.state.save_item
                
                    await axios
                        .post('/api/v1/savings/create/new_campaign/', {'productID': product_id, 'campaignName': this.campaignName, 'campaignType': this.campaignType, 'campaignTargetAmount': this.campaignTargetAmount, 'campaignTargetDate': this.campaignTargetDate, 'campaignDescription': this.campaignDescription})
                        .then(response => {
                            this.$store.commit('setSnackBarMessage', response.data[0].text)
                            this.$store.commit('setSnackBarColor', response.data[0].color)
                            this.$store.commit('activateSnackBar', true)
                            if(response.data[0].id !== 0){
                                let router = this.$router
                                setTimeout(function(){
                                    router.push('/savings/campaigns/detail/'+response.data[0].id+'/')
                                }, 4000)
                            }
                        })
                        .catch(error => {
                            if (error.response){
                                this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                                this.$store.commit('setSnackBarColor', "red darken-4")
                                this.$store.commit('activateSnackBar', true)
                            }else{
                                this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                                this.$store.commit('setSnackBarColor', "red darken-4")
                                this.$store.commit('activateSnackBar', true)
                            }
                        })
                    this.$store.commit('setIsLoading', false)
                }
            },

            getHelpMessage(){
                if (this.campaign_name_help){
                    this.campaign_name_help = false
                    this.$store.commit('setDialogTitle', "Campaign Name")
                    this.$store.commit('setDialogMessage', "Here you are required to input your campaign name. This name is usually descriptive of the objective for your savings.")
                    this.$store.commit('activateDialog', true)
                }else if (this.campaign_type_help){
                    this.campaign_type_help = false
                    this.$store.commit('setDialogTitle', "Campaign Type")
                    this.$store.commit('setDialogMessage', "You are required to choose your campaign type here. We have 4 types of campaigns, (Periodic, Money and Money-Time Target). If your target is a certain amount, choose the MONEY TARGET option. If your target is certain date then you choose TIME TARGET. If your target is a certain amount at a particular time, then choose MONEY-TIME TARGET. In the case of you having no idea, you can choose UNDEFINED.")
                    this.$store.commit('activateDialog', true)
                }else if (this.campaign_amount_help){
                    this.campaign_amount_help = false
                    this.$store.commit('setDialogTitle', "Campaign Amount")
                    this.$store.commit('setDialogMessage', "If you have a certain amount saved as your campaign objective, you will have to input your targeted amount.")
                    this.$store.commit('activateDialog', true)
                }else if (this.campaign_date_help){
                    this.campaign_date_help = false
                    this.$store.commit('setDialogTitle', "Campaign Date")
                    this.$store.commit('setDialogMessage', "If you have a certain date saved as your campaign objective, you will have to input your targeted date.")
                    this.$store.commit('activateDialog', true)
                }else if (this.campaign_description_help){
                    this.campaign_description_help = false
                    this.$store.commit('setDialogTitle', "Campaign Description")
                    this.$store.commit('setDialogMessage', "This entails for you to input the description of why you are setting the objective based savings campaign.")
                    this.$store.commit('activateDialog', true)
                }
            },

            OnChangeCampaignType(){
                if(this.campaignType === "MONEY_TARGET"){
                    this.isAmountDisabled = false
                    this.isDateDisabled = true                    
                    this.isAmountRequired = false
                    this.isDateRequired = true                    
                }
                else if(this.campaignType === "TIME_TARGET"){
                    this.isAmountDisabled = true
                    this.isDateDisabled = false                                        
                    this.isAmountRequired = true
                    this.isDateRequired = false                                        
                }
                else if(this.campaignType === "MONEY_TIME_TARGET"){
                    this.isAmountDisabled = false
                    this.isDateDisabled = false                                        
                    this.isAmountRequired = false
                    this.isDateRequired = false                                        
                }
                else if(this.campaignType === "UNDEFINED TARGET"){
                    this.isAmountDisabled = true
                    this.isDateDisabled = true                                        
                    this.isAmountRequired = true
                    this.isDateRequired = true                                        
                }
            },
        },

    }
</script>
